<template>
  <!--
    A map component.
  -->
  <Portlet
    :title="$t('geoMapComp.map')"
    icon="map-marker"
    class="geoMap"
  >
    <template slot="buttons">
      <button
        :class="['btn btn-sm mr-3', { 'pushed' : this.isEditMode, 'reg-btn' : !this.isEditMode }]"
        @click="changeEditMode()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="edit"
        />
        <span>{{ $t('edit') }}</span>
      </button>
      <button
        v-if="showSaveButton"
        class="btn btn-sm btn-primary  mr-3"
        :disabled="disableSaveButton"
        @click="saveNewLocation()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="save"
        />
        <span>{{ $t('save') }}</span>
      </button>
      <toggle-button
        v-model="baseLayerBool"
        :labels="{ checked: $t('map'), unchecked: $t('satellite') }"
        :color="{ checked: 'gray', unchecked: 'green' }"
        :width="80"
        :height="30"
        :font-size="10"
        class="m-0"
        @change="changeBaseLayer()"
      />
    </template>
    <div
      id="map"
      style="width: 100%; height: 100%; min-height: 500px;"
    />
    <div
      v-if="this.isEditMode"
      class="input-group"
    >
      <input
        v-model="searchText"
        :class="['form-control rounded-0', { 'is-invalid': errors.has(`text`) }]"
        :placeholder="$t('eventMovieComp.searchLocation')"
        type="text"
      >
      <div
        class="input-group-append"
      >
        <button
          class="btn btn-primary rounded-0"
          @click="findLocation()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="search"
          />
          <span>{{ $t('search') }}</span>
        </button>
      </div>
    </div>
    <p
      v-if="this.isEditMode"
      class="mt-3"
    >
      {{ $t('installationDetailsView.geoInstructions') }}
    </p>
    <!-- <template v-else>
      {{ $t('noDataAvailable') }}
    </template> -->
  </Portlet>
</template>

<script>
import L from 'leaflet';

export default {
  name: "GeoMap",
  props: {
    latitude: {
      type: Number,
    },
    longitude: {
      type: Number,
    },
    id: {
      type: String
    }
  },
  data () {
    return {
      zoomIn: false,
      map: null,
      mapAttrib: '&copy; <a href="http://www.esri.com/">Esri</a>',
      mapUrl: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      wikiAttrib: '&copy; <a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>',
      wikiUrl: 'https://a.tile.openstreetmap.org/${z}/${x}/${y}.png',
      contributionUrl: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      baseLayerBool: true,
      baseLayers: null,
      isEditMode: false,
      searchText: null,
      locationMarker: null,
      showSaveButton: false,
      disableSaveButton: true,
    }
  },
  computed: {
    validLocation: function () {
      if (this.latitude && this.longitude) return true;
      return false;
    },
    validLocationText: function () {
      return this.id ? true : false;
    },
    getLatLonText: function () {
      if(this.locationMarker != null) {
        let lat = this.locationMarker.getLatLng().lat;
        let lon = this.locationMarker.getLatLng().lng;
        return lat + ', ' + lon;
      }
      return null;
    },
    getOrgLatLonText: function () {
      return this.latitude + ', ' + this.longitude;
    }
  },
  watch: {
    getLatLonText (val) {
      if(this.getOrgLatLonText != val) {
        this.disableSaveButton = false;
      }
    }
  },
  mounted () {
    this.wikiUrl = 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png';
    this.initMap();
  },
  methods: {
    changeEditMode () {
      this.isEditMode = !this.isEditMode;
      this.showSaveButton = !this.showSaveButton;
      if(this.locationMarker) {
        if(!this.isEditMode) {
          this.locationMarker.dragging.disable();
        }
        else {
          this.locationMarker.dragging.enable();
        }
      }
    },
    updateLatLonText () {
      this.searchText = this.getLatLonText;
    },
    initMap () {
      let wikiMap = L.tileLayer(this.wikiUrl, {attribution: this.contributionUrl});
      let landMap = L.tileLayer(this.mapUrl, {attribution: this.mapAttrib});
      this.baseLayers = {};
      this.baseLayers['Satellite'] = landMap;
      this.baseLayers['Map'] = wikiMap;

      this.map = L.map('map', {
        layers: [
          wikiMap
        ],
        zoomDelta: 0.5, 
        zoomSnap: 0
      });
      this.map.addLayer(wikiMap);
      
      if (this.validLocation) {
        this.locationMarker = L.marker([this.latitude, this.longitude]).addTo(this.map);
        this.locationMarker.on('dragend', () => {
          this.updateLatLonText();
          this.showSaveButton = true;
        });
        let latLngs = [ this.locationMarker.getLatLng() ];
        let markerBounds = L.latLngBounds(latLngs);
        this.map.fitBounds(markerBounds);
        this.searchText = this.getLatLonText;
      } 
      else if (this.validLocationText) {
        this.setLocationMarker().then(() => {
          
          if(!this.searchText) {  
            this.getCustomer().then(() => {
                if(this.searchText != null) {
                  this.findLocation();
                }
              });
          } else {
            this.findLocation().then(() => {
            if(!this.locationMarker) {
              this.getCustomer().then(() => {
                if(this.searchText != null) {
                  this.findLocation();
                }
              });
            }
          });
          }
        });
      }
      else {
        let corner1 = L.latLng(48.357652, 14.031618);
        let corner2 = L.latLng(45.669637, 4.536964);
        let bounds = L.latLngBounds(corner1, corner2);
        this.map.fitBounds(bounds);
      }
    },
    changeBaseLayer () {
      if (this.baseLayerBool == true) {
        this.map.removeLayer(this.baseLayers['Satellite']);
        this.map.addLayer(this.baseLayers['Map']);
      } else {
        this.map.removeLayer(this.baseLayers['Map']);
        this.map.addLayer(this.baseLayers['Satellite']);
      }
    },
    async findLocation () {
      await this.axios.get(`/Location/GetByName?text=${ this.searchText }`)
        .then((response) => {
          let quit = false;
          if (response == null) {
            quit = true;
          }
          if (response.status == null) {
            quit = true;
          }
          if (response.status == 204) {
            this.$snotify.error(this.$t('eventMovieComp.locationNotFoundMessage'), this.$t('eventMovieComp.locationNotFoundTitle'));
            quit = true;
          }
          if (response.data == null) {
            quit = true;
          }
          if (response.data.latitude == null || response.data.longitude == null) {
            quit = true;
          }

          if(quit) {
            let corner1 = L.latLng(48.357652, 14.031618);
            let corner2 = L.latLng(45.669637, 4.536964);
            let bounds = L.latLngBounds(corner1, corner2);
            this.map.fitBounds(bounds);
            return;
          }
          
          if (this.locationMarker != null) {
            this.map.removeLayer(this.locationMarker)
          }
          this.locationMarker = L.marker([response.data.latitude, response.data.longitude]).addTo(this.map);
          this.locationMarker.on('dragend', () => {
            this.updateLatLonText();
            this.showSaveButton = true;
          });
          let latLngs = [ this.locationMarker.getLatLng() ];
          let markerBounds = L.latLngBounds(latLngs);
          this.map.fitBounds(markerBounds);
        })
        .catch((error) => {
          if (error.status == null) {
            return;
          }
          this.error_clear();
          this.error_validate(error);
        });
    },
    async setLocationMarker () {
      await this.axios.get('/Issue/Get?issueId=' + this.id)
        .then((response) => {
          this.searchText = response.data.location;
        });
    },
    async getCustomer () {
      await this.axios.get('/Installation/GetCustomer?installationId=' + this.id)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          
          if(!response.data && !response.data.result) {
            let corner1 = L.latLng(48.357652, 14.031618);
            let corner2 = L.latLng(45.669637, 4.536964);
            let bounds = L.latLngBounds(corner1, corner2);
            this.map.fitBounds(bounds);
            return;
          }
          this.searchText = response.data.result.customerCity;
        });
    },
    saveNewLocation () {
      if(this.getLatLonText) {
        this.axios.put('/Installation/UpdateLocation?id=' + this.id + '&latLon=' + this.getLatLonText)
        .then(() => {
          this.isEditMode = false;
          this.showSaveButton = false;
          this.$emit("updateDetails");
      });
      }
    }
  }
}
</script>

<style scoped>
.pushed {
 background-color: #65656563; 
}
.reg-btn {
  background-color: white;
}
</style>
